import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import SEO from '../components/seo'
import { tablet, desktop } from '../breakpoints';
import banner from '../images/desk.jpg';
import carl from '../images/carlcox.jpg';
import studio from '../images/studio.jpg';
import brands from '../images/brands.jpg';

class LivePage extends Component {

	constructor(){
		super();
        this.state = {
            videoPlaying: false
        }
	}

	componentDidMount(){

	

	}

	
	componentWillUnmount(){
	
	}

	/*
    |--------------------------------------------------------------------------
    | watch for prop updates
    |--------------------------------------------------------------------------
    */
   	componentWillReceiveProps(next){
		
	}

	render() { 

	
		return (
			<Page>

				<SEO title="Production" keywords={[`radio`, `award winning`, `production`]} />

				<Wrapper>
					<Title>Production</Title>
					
                    <Banner>                  
                        <div className="inner"><img src={banner} /></div>
                    </Banner>
				</Wrapper>
               
                

                <LiveInfo>
					

					<Inner>
                        
                        <div className="intro inner">
                            <p>A curated roster of weekly syndicated music programming is the beating heart of everything we do. We have longstanding trusted relationships with a worldwide network of FM, digital stations and streaming platforms, and by handpicking only the very best talent, we uphold an unsurpassed reputation.</p>
                        </div>
						
                        <Container>
							<Left>
								<IntroPics>
                                <img src={brands} />
                                   
                                </IntroPics>
							</Left>
							<Right >
								<Divider/>
								<Subtitle>For Artists &amp; Brands</Subtitle>
                                <p>Our aim is to make your show a global success, keeping you fresh in the minds of millions of fans every week. </p>
                                <p>From initial concept ideas through to our industry-leading sound design, we will put our full weight behind building your show into an established, premium radio brand.  </p>
								<p>We will work closely with you throughout – developing your presenting style, while fine-tuning your scripts and fully-producing your shows so that they sound incredible. </p>
							</Right>
						</Container>

                        <ContainerSecond>
							<Left>
                                 <Divider/>
                                <Subtitle>For Radio Stations &amp; Platforms </Subtitle>
                                <p>For two decades we have delivered quality content to more than 400+ platforms and broadcasters around the world.</p>
								<p>We understand what our partners want, and we deliver what their audiences love.</p>
								<p>Operationally, it’s seamless. Shows are highly produced, flawlessly edited, and delivered at the exact same time every week – and we offer bespoke identity packages too. </p>
							</Left>
							<Right >
								<IntroPics>
                                <img src={studio} />
                                </IntroPics>
							</Right>
						</ContainerSecond>

                        <Container>
							<Left>
								<IntroPics>
                                <img src={carl} />
                                   
                                </IntroPics>
							</Left>
							<Right >
								<Divider/>
								<Subtitle>For Online &amp; Social </Subtitle>
                                <p>Today’s radio can’t just sound great, it’s got to look great too. We work with all our artists to devise a cohesive online plan, deciding where their show is best placed and consulting on social media strategy. We create weekly visual assets for stations to share that turbo-charges audience engagement across digital platforms. </p>
                                
							</Right>
						</Container>

					</Inner>
					
				</LiveInfo>
			
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  })(LivePage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;
`

const Wrapper = styled.section`
	padding-top:100px;
	background:#c2b0bc;
`

const CallToAction = styled.section`
    background:#EE727F;
    padding:70px 40px;

    .inner {
        display:flex;
        max-width:1100px;
        margin:auto;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: ${tablet}) {
            display:block;
        }

        button {
            border:1px solid #000;
            border-radius:30px;
            background:transparent;
            padding:9px;
            font-size:16px;
            margin-right: 15px;
            outline:none;
            width:45%;

            &:hover {
                background: #000;
                color:#EE727F;
                cursor:pointer;
            }

            @media only screen and (max-width: ${tablet}) {
                padding: 9px;
                width:100%;
                font-size: 12px;
                margin-right: 0;
                margin-bottom:20px;

                &:last-child {
                    margin-bottom:0;
                }
            }
        }
    }
`

const LiveInfo = styled.section`
	min-height:100%;
	padding-top:40px;
    background:rgb(253, 247, 244);
    color:#000;
    font-size:22px;
	line-height:1.6em;	

    .inner {
        padding:0 40px 40px 40px;
    }

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 
`
const Banner = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    padding-top: 25px;
	padding-bottom: 40px;

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:0;
	 }	
	
	img {
		max-width:100%;
	}

    .inner {
        padding:0 40px 40px 40px;
    }
`

const Title = styled.h1`
	font-size: 7vw;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
	margin-bottom:30px;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
		margin-bottom:0;
	 }

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`

const Container = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column-reverse;
		padding-bottom:0;
	 }
`

const ContainerSecond = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column;
		padding-bottom:0;
     }
     
    
`

const IntroPics = styled.div`
     padding-top:10px;

     img {
         max-width:100%;
         margin-bottom:20px;
     }

     img:last-child {
         margin-bottom:0;
     }
`

const Inner = styled.div`
	max-width:1100px;
	width:100%;
    margin:auto;

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;

            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
             }	
        }
    }
`

const Left = styled(Container)`
	margin-right: auto;
	width:50%;
	padding-right:2.5%;
    position:relative;
    display:block;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		padding:10px;
        padding-bottom:40px;
	 }

	 .imageWrapper {
		overflow: hidden;
		position: absolute;
		top: 20px;
		left: 20px;
		right: 0;
		bottom: 0;
		width: 90%;
		height: 100%;
		z-index:100;
		transition: 1.2s ease;
	 }

	 .imageWrapper.outToLeft {
		 width: 0%;
		 height:100% !important;
		 transition: width 1.2s ease;
	 }

	 .imageWrapper.outFromBottom {
		height: 0%;
		transition: height 1.2s ease;
	}

	 .gatsby-image-wrapper {
		width: 100%;
		margin-top:10px;

		@media only screen and (max-width: ${tablet}) {
			width:100% !important;
			
		 }

		img {
			height:auto !important;
			max-width:411px;

			@media only screen and (max-width: ${tablet}) {
				
				width:350px !important;
			 }
		}
	}
`

const Subtitle = styled.h2`
    font-family:'EuclidFlex';
    font-weight:normal;
    font-size: 28px;
`

const Right = styled(Container)`
	margin-left: auto;
	display:block;
	width:50%;
	color:#000;
    font-size:22px;
	line-height:1.6em;	
	padding-left:2.5%;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 

	ul.meta {
		display:flex;
		flex-direction: row;
		align-items: center;
		padding:0;
		justify-content: flex-start;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			display:inline-block;

			svg {
				width:16px;
				height:16px;
				fill:#000;
			}
		}

		li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6), li:nth-child(7), li:nth-child(8)  {
			button {
				border-radius:50%;
				border:1px solid #000;
				width:35px;
				height:35px;
				text-align:center;
			}
		}

		li.button-stop, li.button-pause, li.button-play, li.button-playdemo, li.button-play {
			display:none;
		}

		li.show {
			display:block;
		}

		li.hide {
			display:none !important;
		}

		button.btn-fb {
			svg {
				position:relative;
				left:-1px;
			}
		}


		button.btn-play {
			svg {
				font-size: 14px;
				height: 14px;
				position: relative;
				left: 1px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 1px;
					top: 1px;
				}
				
			}
		}

		button.btn-stop {

			svg {
				width: 12px;
				height: 12px;
				position: relative;
				top: -2px;
				left: 0;

				@media only screen and (max-width: ${tablet}) {
					top:2px;
					left:-1px;
				}
			}
		}

		button.btn-pause {
			svg {
				width: 14px;
				height: 14px;
				position: relative;
				left: 0px;
				top: -1px;

				@media only screen and (max-width: ${tablet}) {
					left: 0px;
    				top: 1px;
				}
				
			}
		}

	}

	button {
		border:1px solid #000;
		border-radius:30px;
		background:transparent;
		padding:9px;
		width:100px;
		font-size:16px;
		margin-right: 15px;
		outline:none;

		&:hover {
			background: #000;
			color:#F7B295;
			cursor:pointer;
			
			svg {
				fill:#F7B295 !important;
			}
		}

		@media only screen and (max-width: ${tablet}) {
			padding: 9px;
			width: 80px;
			font-size: 12px;
			margin-right: 10px;
		}
		
	}
`

const Divider = styled.hr`
	border:none;
	border-bottom:2px solid #000;
`

const MoreArtsts = styled.section`
	padding:40px;

	ul {
		margin:0;
		padding:0;
		column-count: 2;
		margin-top:40px;

		li {
			list-style:none;
			margin:0;
			padding:0;
			text-align:center;
			font-size:22px;
			display:block;
			margin-bottom:5px;

			@media only screen and (max-width: ${tablet}) {
				font-size:18px;
			}

			a {
				color:#000;
				text-decoration:none;

				&:hover {
					text-decoration:underline;
				}
			}
		}

		li.active {
			a{ 
				text-decoration:underline;
			}
		}
	}
`